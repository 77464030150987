import { type useLoaderData } from "@remix-run/react"
import { useParentRouteData } from "#app/utils/remix"
import { type loader as articleLoader } from "../article.$articleId.server"

export type LoaderArticle = ReturnType<typeof useLoaderData<typeof articleLoader>>["article"]
export type LoaderHighlight = LoaderArticle["highlights"][0]

export function useArticleRouteData() {
	return useParentRouteData<typeof articleLoader>("routes/_article+/article.$articleId")
}
